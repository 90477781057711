














































import fileUpload from '@/components/FilesUpload.vue';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Vue from "vue";
import {axiosDelete, axiosGet} from '@/utils/axiosWrapper';
import {FILES_LOCATION, MEDIA_LOCATION} from '@/config';

interface Files {
  name: string;
  link: string;
  type?: string;
}

export default Vue.extend({
  name: "Files",
  data() {
    return {
      showSnackbar: false,
      sending: false,
      files: [] as Files[]
    }
  },
  methods: {
    loadData: function () {
      this.files = []
      axiosGet('/files')
          .then((response) => {
            response.data.forEach((elem: string) => {
              this.files.push({
                name: elem,
                link: this.isImage(elem.split('.').pop()) ? (MEDIA_LOCATION + elem) : ((FILES_LOCATION + elem)),
                type: elem.split('.').pop()
              })
            })
          })
          .catch((error) => {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });
    },
    testFileEnding: function (allowedFormats: string[], file?: string) {
      return Boolean((file && allowedFormats.includes(file)))
    },
    isImage: function (file?: string): boolean {
      let allowedFormats = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "webp"]
      return this.testFileEnding(allowedFormats, file);
    },
    isAudio: function (file?: string): boolean {
      let allowedFormats = ["mp3", "MP3", "ogg", "OGG"]
      return this.testFileEnding(allowedFormats, file);
    },
    onSelect: function (item: Record<string, string>) {
      this.copyToClipboard(item.link).then(() => {
        this.showSnackbar = true;
      }, (e) => {
        console.warn('Can not copy\n' + e)
      })
    },
    deleteEntry: async function (id: string) {
      const deleted = await axiosDelete(`/files/${id}`)
      this.files = this.files.filter(e => e.name !== id)
      console.log(deleted.data)
    },
    copyToClipboard(link: string): Promise<void> {
      /***
       * Alternative Clipboard Copy function to be able to use in development area.
       * Source: https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
       */
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(link)
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = link;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        })
      }
    },
  },
  components: {
    fileUpload
  },
  mounted() {
    this.loadData()
  }
})
